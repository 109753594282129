import React from 'react';
import './App.css'
import { Navbar } from "react-bootstrap";
import IncomemaxLogo from "./images/incomemax-logo.svg";
import "./header.style.css";
import { useHistory } from 'react-router-dom';


function Header() {

    const history = useHistory();

    const goToHome = () => {
        history.push('https://whd.incomemax.org.uk');
    }

    return (
        <div style={{ maxHeight: "75px" }}>
            <div className="header-container">
                <Navbar.Brand className="header-logo" onClick={goToHome}>
                    <img src={IncomemaxLogo} />
                </Navbar.Brand>
                <div className="navTypography-header" >
                    <p className="text-header">
                        Brought to you by {"\xa0"} <a style={{ color: "#000" }} href="https://incomemax.org.uk/"> IncomeMax.org.uk</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Header;
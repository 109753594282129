import React, { useState } from "react"
import { API } from "aws-amplify"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"
import EmailValidator from 'email-validator'
import { Button, Form } from 'react-bootstrap';

import Back from '../images/arrow-back.svg';

import "./Form.css"

const MAX_FILE_SIZE = 10 // MB
const MAX_NUMBER_FILES = 5

const recaptchaRef = React.createRef();

const FormComponent = () => {

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    var isGenericPartner;

    if (urlParams.get('partner') == 'e-energy') {
        isGenericPartner = true;
    } else {
        if (!urlParams.get('d1s')) {
            window.location.href = 'https://whd.incomemax.org.uk';
        }
    }

    var document1 = { selection: urlParams.get('d1s'), document: urlParams.get('d1d') }
    var document2 = { selection: urlParams.get('d2s'), document: urlParams.get('d2d') }

    const onCancelBtnClick = () => {
        window.location.href = 'https://whd.incomemax.org.uk';
    };

    const doc2Container = () => {
        if (isGenericPartner) return;
        if (!urlParams.get('d2s')) return;
        return <div className="document-overview">
            <h4>You selected:</h4>
            <p><i>{document2.selection}</i></p>
            <h4>We need:</h4>
            <p>{document2.document}</p>
        </div>
    }

    const title = () => {
        if (isGenericPartner) return "E-Energy Warm Home Discount";
        else return "Follow the instructions to complete your verification.";
    }

    const docOverview = () => {
        if (isGenericPartner) return;
        return <div className="document-overview">
            <h4>You selected:</h4>
            <p><i>{document1.selection}</i></p>
            <h4>We need:</h4>
            <p>{document1.document}</p>
        </div>
    }

    const keepInMindMessage = () => {
        if (isGenericPartner) return;
        return <div className="opt-out-info">
            <h3>Keep in mind!</h3>
            <p>When uploading a document make sure you’re scanning every page we need, specified on this page</p>
        </div>
    }
    const genericSubMessage = () => {
        if (!isGenericPartner) return;
        return <div><p>Please upload your requested documents here</p></div>
    }

    const backButtonContainer = () => {
        if (isGenericPartner) return;
        return <div className="sub-nav-container">
            <div className="signup-btn-div">
                <Button onClick={onCancelBtnClick} className="signup-btn learn-btn">
                    <img src={Back} /> Back
                </Button>
            </div>
        </div>
    }

    const landline = () => {
        if (isGenericPartner) return;
        return <div>
            <Form.Control size="lg" type="text" placeholder="Landline number"
                onChange={handleChange}
                value={state.landlineNumber}
                name={"landlineNumber"}
                type="text"
                validate
            />
            <p>Optional</p>
        </div>
    }

    const phonenumber = () => {
        if (isGenericPartner) {
            return <div>
                <Form.Control size="lg" type="text" placeholder="Phone number"
                    onChange={handleChange}
                    value={state.mobileNumber}
                    name={"mobileNumber"}
                    type="text"
                    validate
                    required
                />
                <p>&nbsp;</p>
            </div>
        }
        return <div>
            <Form.Control size="lg" type="text" placeholder="Mobile number"
                onChange={handleChange}
                value={state.mobileNumber}
                name={"mobileNumber"}
                type="text"
                validate
                required
            />
            <p>&nbsp;</p>
        </div>
    }

    const edfAccountNumber = () => {
        if (isGenericPartner) return;
        return <div>
            <Form.Control size="lg" type="text" placeholder="EDF account number"
                onChange={handleChange}
                value={state.accountNumber}
                name={"accountNumber"}
                type="text"
                validate
                required
            />
            <p>&nbsp;</p>
        </div>
    }

    const topView = () => {
        return <>
            <div className="form-section">
                <h2>Your documents</h2>
                <div className="top-container">
                    {docOverview()}
                    {doc2Container()}
                    {genericSubMessage()}
                    <div>
                        {keepInMindMessage()}
                        <div className="file-size-info">
                            <p><strong>Max number of files: </strong>5</p>
                            <p><strong>Max file size: </strong>10mb per file</p>
                            <p><strong>Supported file types: </strong>JPG, PNG, PDF</p>
                        </div>
                    </div>
                </div>
                <div className="file-add-button">
                    <input
                        name={"files[]"}
                        validate
                        required
                        onChange={(e) => handleFiles(e.target.files)}
                        type="file"
                        multiple={true}
                        error="wrong"
                        success="right"
                        accept="image/png, image/jpeg, application/pdf" />
                </div>
            </div>
        </>
    }

    const formView = () => {
        return <>
            <div className="opt-out-landing-body">
                {backButtonContainer()}
                <h1 className="hero-title">
                    {title()}
                </h1>
            </div>
            {topView()}
            <div className="text-center">
                {Object.keys(state.errors).length ?
                    Object.keys(state.errors).map(errorIdx => (
                        <div>
                            {state.errors[errorIdx]}
                        </div>
                    ))
                    : ""
                }
            </div>
            <div className="form-section">
                <h2>Your personal details</h2>
                <div className="form-container">
                    <div className="form">
                        <div>
                            <Form.Control size="lg" type="text" placeholder="First name" label="Your first name"
                                onChange={handleChange}
                                value={state.firstname}
                                name={"firstname"}
                                group
                                type="text"
                                validate
                                required
                                error="wrong"
                                success="right"
                                type="text" />
                            <p>&nbsp;</p>
                        </div>
                        <div>
                            <Form.Control size="lg" type="text" placeholder="Last name" label="Your surname"
                                onChange={handleChange}
                                value={state.lastname}
                                name={"lastname"}
                                type="text"
                                validate
                                required />
                            <p>&nbsp;</p>
                        </div>
                        {phonenumber()}
                        {landline()}
                        <div>
                            <Form.Control size="lg" type="text" placeholder="Email address" label="Your email"
                                onChange={handleChange}
                                value={state.emailFrom}
                                name={"emailFrom"}
                                validate
                                required
                                type="email" />
                            <p>&nbsp;</p>
                        </div>
                        {edfAccountNumber()}
                    </div>
                </div>
                <div className="btn-container">
                    <Button disabled={state.loading} className="dark-btn-whd" type={"submit"}>
                        {state.loading ?
                            <p>Processing...</p>
                            : "Submit"
                        }
                    </Button>
                </div>
            </div>
        </>
    }

    const [state, setState] = useState({
        firstname: "",
        lastname: "",
        landlineNumber: "",
        mobileNumber: "",
        accountNumber: "",
        requiredDocuments: [document1, document2],
        emailFrom: "",
        files: [],
        errors: {},
        success: false,
        rawFiles: [],
        fileUrls: [],
        loading: false
    })

    const handleCaptureChange = (value) => {
        console.log("Captcha value:", value);
    }

    const handleChange = (e) => {
        const name = e.target.name;
        setState({
            ...state,
            [name]: e.target.value
        });
    }

    const checkFilesSize = files => {
        let totalSize = 0
        for (let idx = 0; idx < files.length; idx++) {
            totalSize += files[idx].size
        }
        return totalSize < MAX_FILE_SIZE
    }

    const checkFilesCount = files => {
        return files.length <= MAX_NUMBER_FILES
    }

    const handleFiles = files => {
        let allFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let rawFiles = state.rawFiles;
            rawFiles.push(file);
            setState({ ...state, rawFiles: rawFiles })
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                allFiles.push({
                    name: file.name,
                    type: file.type,
                    size: file.size / Math.pow(1024, 2), // bytes to Mb
                    base64: reader.result
                });
                if (allFiles.length == files.length) {
                    if (checkFilesSize(allFiles)) {
                        const errors = { ...state.errors }
                        delete errors.maxSizeExceeded
                        setState({ ...state, files: allFiles, errors: errors })
                    } else {
                        const errors = { ...state.errors, maxSizeExceeded: `Max size of files is ${MAX_FILE_SIZE} Mb` }
                        setState({ ...state, success: false, errors: errors })
                    }
                    if (checkFilesCount(allFiles)) {
                        const errors = { ...state.errors }
                        delete errors.maxCountExceeded
                        setState({ ...state, files: allFiles, errors: errors })
                    } else {
                        const errors = { ...state.errors, maxCountExceeded: `The maximum number of files you can upload is ${MAX_NUMBER_FILES}` }
                        setState({ ...state, success: false, errors: errors })
                    }
                }
            }
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        var isEmailValid = EmailValidator.validate(state.emailFrom);

        if (!isEmailValid) {
            alert('Please check your email address')
            return;
        }

        if (state.mobileNumber.length < 10) {
            if (isGenericPartner) {
                alert('Please check your phone number')
            } else {
                alert('Please check your mobile phone number')
            }
            return;
        }

        if (state.errors.maxCountExceeded || state.errors.maxSizeExceeded) return;
        setState({ ...state, loading: true, success: false })
        for (let idx = 0; idx < state.rawFiles.length; idx++) {
            let file = state.rawFiles[idx]
            API.get("uploadurl", "/uploadurl", {
                queryStringParameters: {
                    filename: file.name,
                    filetype: file.type
                },
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(async (response) => {
                var signedUrl = response.data.presignedUrl;
                let fileUrls = state.fileUrls;
                fileUrls.push(response.data.filename);
                setState({ ...state, fileUrls: fileUrls, loading: true })
                var options = {
                    headers: {
                        ContentType: file.type
                    }
                };
                axios.put(signedUrl, file, options)
                    .then(result => {

                        if (idx === state.rawFiles.length - 1) {
                            API.post("files", "/files", {
                                body: {
                                    firstname: state.firstname,
                                    lastname: state.lastname,
                                    emailFrom: state.emailFrom,
                                    landlineNumber: state.landlineNumber,
                                    mobileNumber: state.mobileNumber,
                                    accountNumber: state.accountNumber,
                                    requiredDocuments: state.requiredDocuments,
                                    fileUrls: state.fileUrls,
                                    isGenericPartner: isGenericPartner
                                },
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }).then(async (response) => {

                                setState({
                                    ...state,
                                    loading: true,
                                    success: true,
                                    errors: []
                                })
                            }).catch((e) => {
                                const errors = { ...state.errors, serverError: e.response.data.message }

                                setState({ ...state, success: false, errors: errors })
                            })
                        }
                    })
                    .catch(error => {
                        if (error) {
                            console.log(error)
                        }
                    })
            }).catch((e) => {
                console.log("err" + e)
            })
        }
    }

    return (
        <>
            <div className="mainContainer">
                <form encType="multipart/form-data" onSubmit={onSubmit}>
                    {!state.success ?
                        <div>
                            {formView()}
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LcMisAcAAAAACTTDJvsk4tG38izf89IZNN8TIip"
                                onChange={handleCaptureChange}
                                size="invisible"
                            />
                        </div>
                        : ""
                    }
                    {state.success ?
                        <div className="success-content-container">
                            <div className="successMessage">
                                <h1 className="hero-title">
                                    You have completed verification
                                </h1>
                                <div className="successContent">
                                    <p>Thank you. Your file upload has been successful. A confirmation email has been sent to the email provided.</p>
                                    <p>Our team are reviewing your documents and will let you know soon if your Warm Home Discount application has been successful.</p>
                                    <p>Please keep an eye out for the results email from IncomeMax. You can now close this window. </p>
                                    <Button onClick={onCancelBtnClick}>Home</Button>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </form>

            </div>
        </>
    )
}

export default FormComponent
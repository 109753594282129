import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import Form from "./components/Form";
import { Button } from "react-bootstrap";
import Footer from './Footer';
import Header from './Header';

import "./custom.css";

Amplify.configure(awsconfig);

const App = () => {




   

    return (
        <div className="App">
        <Header />
                        <Form/>

                        </div>
                   
    )
}

export default App;
